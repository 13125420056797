<template>
    <v-dialog
        v-model="newCourseDialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                tile
            >
                <v-icon>mdi-plus</v-icon>
                Tilføj
            </v-btn>
        </template>
        <v-card>
            <v-toolbar color="primary" dark class="mb-5">
                <v-card-title>
                    Tilføj
                </v-card-title>
            </v-toolbar>
            <v-card-text>
                <v-form>
                    <v-text-field
                        label="Titel"
                        v-model="newCourse.name"
                        outlined

                    />
                    <v-text-field
                        label="Url"
                        v-model="newCourse.url"
                        outlined
                    />
                    <v-textarea
                        label="Beskrivelse"
                        v-model="newCourse.description"
                        outlined
                    />
                    <v-select
                        label="Kategori"
                        :items="courseTypes"
                        item-text="name"
                        item-value="id"
                        outlined
                        v-model="newCourse.courseType"
                    />
                    <v-text-field
                        label="Estimeret tid"
                        v-model="newCourse.estimated"
                        outlined
                    />
                    <v-text-field label="Oprettet af" disabled :value="$store.state.auth.user.name"/>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-5 pr-6">
                <v-spacer/>
                <v-btn
                    color="primary"
                    :loading="addCourseLoad"
                    @click="addCourse()"
                >Gem
                </v-btn>
                <v-btn
                    outlined
                    color="red"
                    @click="newCourseDialog = !newCourseDialog"
                >Annuller
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import api from "../services/api";
    export default {
        name: "newCourseDialog",
        data() {
            return {
                courseTypes: [],
                newCourseDialog: false,
                newCourse: {
                    name: '',
                    description: '',
                    estimated: '',
                    courseType: '',
                    url: '',
                    createdBy: this.$store.state.auth.user.id,
                },
                addCourseLoad: false,
            }
        },
        methods:{
            getCourseTypes() {
                api.get('course/types')
                    .then(response => {
                        this.courseTypes = response.data
                    })
            },
            addCourse() {
                this.addCourseLoad = true
                api.post('/course/add', this.newCourse)
                    .then(response => {
                        if (response.data.error) {
                            let errors = '';
                            response.data.error.forEach((v, k) => {
                                errors += `<br/>${v.message}`
                            })
                            this.$store.dispatch('snackBar/snackAlert', "<b>Fejl</b> " + errors)
                        }else if (response.data === true) {
                            this.$store.dispatch('snackBar/snackSuccess', 'Kursus: "' + this.newCourse.name + '" tilføjet')
                        }
                    })
                    .then(() => {
                        this.addCourseLoad = false
                    })
            },
        },
        mounted() {
            this.getCourseTypes()
        }
    }
</script>

<style scoped>

</style>