var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"col-md-8 col-xs-12 col-sm-12"},[_c('v-card',{staticClass:"pa-0",attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":"","elevation":"0"}},[_c('span',[_vm._v("Kurser")]),_c('v-spacer'),_c('new-course-dialog')],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søg","single-line":"","hide-details":"","dense":""},model:{value:(_vm.courseSearch),callback:function ($$v) {_vm.courseSearch=$$v},expression:"courseSearch"}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.courses,"headers":_vm.headers,"search":_vm.courseSearch,"items-per-page":-1,"dense":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.courseType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.courseType(item.courseType))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'Lektion',params:{id:item.id}}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [(item.completed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-license")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-license")])]}},{key:"item.ratings",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"yellow darken-2","size":"20"}},[_vm._v("mdi-star")]),_vm._v(" "+_vm._s(_vm.rating(item.ratings))+" ")]}},{key:"header.ratings",fn:function(){return [_c('v-icon',{attrs:{"color":"yellow darken-2","size":"20"}},[_vm._v("mdi-star")])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }