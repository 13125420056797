<template>
    <v-container class="col-md-8 col-xs-12 col-sm-12">
        <v-card class="pa-0" dense elevation="0">
            <v-toolbar color="primary" dark dense elevation="0">
                <span>Kurser</span>
                <v-spacer/>
                <new-course-dialog/>
            </v-toolbar>
            <v-card-title>
                <v-text-field
                    v-model="courseSearch"
                    append-icon="mdi-magnify"
                    label="Søg"
                    single-line
                    hide-details
                    dense
                ></v-text-field>

            </v-card-title>
            <v-card-text class="pa-0">
                <v-data-table
                    :items="courses"
                    :headers="headers"
                    :search="courseSearch"
                    :items-per-page="-1"
                    dense
                    :loading="loading"
                >
                    <template v-slot:item.courseType="{ item }">
                        {{courseType(item.courseType)}}
                    </template>
                    <template v-slot:item.name="{ item }">
                        <router-link :to="{name:'Lektion',params:{id:item.id}}">{{item.name}}</router-link>
                    </template>
                    <template v-slot:item.completed="{ item }">
                        <v-icon color="green" v-if="item.completed">mdi-license</v-icon>
                        <v-icon color="grey" v-else>mdi-license</v-icon>
                    </template>
                    <template v-slot:item.ratings="{item}">
                        <v-icon color="yellow darken-2" size="20">mdi-star</v-icon>
                        {{rating(item.ratings)}}
                    </template>
                    <template v-slot:header.ratings>
                        <v-icon color="yellow darken-2" size="20">mdi-star</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import api from "../services/api";
    import NewCourseDialog from "../components/newCourseDialog";


    export default {
        name: "Kurser",
        components: {NewCourseDialog},
        data() {
            return {
                courseTypes: [],
                courses: [],
                headers: [
                    {text: '#', value: 'completed', width: '24px', align: 'center'},
                    {text: 'Kategori', value: 'courseType', width: '120px'},
                    {text: 'Titel', value: 'name'},
                    {text: 'Varighed', value: 'estimated', width: '100px'},
                    {text: 'ratings', name: 'ratings', value: 'ratings', width: '90px'},
                ],
                courseSearch: '',
                panel: 0,
                loading: true,
            }
        },
        methods: {
            getCourseTypes() {
                api.get('course/types')
                    .then(response => {
                        this.courseTypes = response.data
                    })
            },
            getCourses() {
                api.get('course/all')
                    .then(response => {
                        this.courses = response.data
                        this.loading = false
                    })
            },
            courseType(ctid) {
                let course = this.courseTypes.find(x => x.id == ctid)
                if (typeof course !== 'undefined') {
                    return course.name
                }
                return '-'
            },
            rating(ratings) {
                if (ratings.length >= 1) {
                    return (ratings.reduce((s, v) => {
                        return s + v
                    }, 0) / ratings.length).toFixed(1)
                }
                return '-'
            }
        },
        computed: {},
        mounted() {
            this.getCourseTypes()
            this.getCourses()
        }

    }
</script>

<style scoped>

</style>